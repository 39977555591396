<template>
  <v-container>
    <h1>{{ $t('404.title') }}</h1>
    <p>{{ $t('404.body') }}</p>

    <a href="/"><h2>{{ $t('404.body') }}</h2></a>
  </v-container>
</template>

<script>
export default {

};
</script>
