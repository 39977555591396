<template>
  <div class="mt-5">
    <v-img
      src="@/assets/hero.svg"
      max-height="650"
      contain
    />
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
};
</script>
