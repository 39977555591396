var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('social-button',{attrs:{"link":_vm.twitter}},[_c('i',_vm._g({staticClass:"fab fa-twitter"},on))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('findSpeaker.links.twitter')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('social-button',{attrs:{"link":_vm.linkedIn}},[_c('i',_vm._g({staticClass:"fab fa-linkedin-in"},on))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('findSpeaker.links.linkedIn')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('social-button',{attrs:{"link":_vm.facebook}},[_c('i',_vm._g({staticClass:"fab fa-facebook-f"},on))])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('findSpeaker.links.facebook')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('social-button',{attrs:{"link":_vm.website}},[_c('v-icon',_vm._g({},on),[_vm._v(" web ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('findSpeaker.links.website')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('social-button',{attrs:{"link":_vm.priorPresentation}},[_c('v-icon',_vm._g({},on),[_vm._v(" ondemand_video ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('findSpeaker.links.samplePresentation')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }